export const ConfigData = [
  {
    id: "no",
    name: "NO",
    isShow: true,
    width: "100px",
    filter: true,
  },
  {
    id: "tpa",
    name: "TPA",
    isShow: true,
    width: "120px",
    filter: true,
  },
  {
    id: "region",
    name: "REGION",
    isShow: true,
    width: "130px",
    filter: true,
  },
  {
    id: "area",
    name: "AREA",
    isShow: true,
    width: "120px",
    filter: true,
  },
  {
    id: "clinic_name",
    name: "CLINIC NAME",
    isShow: true,
    width: "180px",
    filter: true,
  },
  {
    id: "address",
    name: "ADDRESS",
    isShow: true,
    width: "400px",
    filter: true,
  },
  {
    id: "phone",
    name: "TEL",
    isShow: true,
    width: "120px",
    filter: true,
  },
  {
    id: "monday_friday",
    name: "MONDAY - FRIDAY",
    isShow: true,
    width: "400px",
    filter: true,
  },
  {
    id: "saturday",
    name: "SATURADAY",
    isShow: true,
    width: "200px",
    filter: true,
  },
  {
    id: "sunday",
    name: "SUNDAY",
    isShow: true,
    width: "200px",
    filter: true,
  },
  {
    id: "public_holiday",
    name: "PUBLIC HOLIDAY",
    isShow: true,
    width: "200px",
    filter: true,
  },
  {
    id: "remarks",
    name: "REMARKS",
    isShow: true,
    width: "200px",
    filter: true,
  },
  {
    id: "hsc",
    name: "HSC",
    isShow: true,
    width: "120px",
    filter: true,
  },
  {
    id: "claimable",
    name: "Claimable",
    isShow: true,
    width: "120px",
    filter: true,
  },
  {
    id: "button",
    name: "Action",
    type: "dropdown",
    isShow: true,
    width: "117px",
    action: ["Delete"],
    permission: {
      Delete: {
        id: "Delete",
        permission: "clinic.delete_clinic",
      },
    },
  },
];

export const dropdownConfig = [
];

export const statusConfig = [
 
];

export const DefaulSorting = {
  id: "created_on",
  name: "Created On",
  isShow: true,
  width: "160px",
  filter: true,
};
