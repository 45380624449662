import { Step } from "@progress/kendo-react-layout";
import "./custom.css";
import moment from "moment";

export const CustomStep = (props) => {
  return (
    <Step {...props}>
      <span className="k-step-indicator custom-step">
        <span className="k-step-indicator-icon k-icon" />
      </span>
      <span
        className="k-step-label"
        style={{
          flexDirection: "column",
          alignItems: "start",
        }}
      >
        <span
          style={{
            marginBottom: "5px",
            whiteSpace: "initial",
            width: "100%",
            fontWeight: 700,
            fontSize: "16px",
          }}
        >
          {props.desc}
        </span>
        <span
          className="custom-audit-name"
          style={{ fontWeight: 500, fontSize: "16px", lineHeight: "22.4px" }}
        >
          By {props.created_by.name}
        </span>
        <span
          style={{
            marginBottom: "20px",
            fontWeight: 500,
            fontSize: "16px",
            lineHeight: "19.6px",
          }}
        >
          {props.changed_at.includes("T")
            ? moment(new Date(props.changed_at)).format("DD MMM YYYY, HH:mm a")
            : props.changed_at}
        </span>
      </span>
    </Step>
  );
};
